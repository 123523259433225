import React from "react";

import { Props } from "../../types/Label02";

/**
 * 汎用ラベルを返します、ulで囲うことを想定
 * 折り返ししませんので注意
 */
export const Presenter: React.VFC<Props> = ({ label, TagName = "li" }) => {
  return (
    <TagName
      className={`inline-block whitespace-nowrap rounded-2xl border border-solid border-primary bg-secondary px-4 py-1 text-xs font-bold text-primary`}
    >
      {label}
    </TagName>
  );
};
