import React from "react";

import MyLink from "../MyLink";

import { Props } from "../../types/Card01";
import { Label02 } from "../Label02";
import { WpImage } from "../WpImage";

/**
 * 施工事例に使用
 */
export const Presenter: React.VFC<Props> = ({
  heading,
  link,
  description,
  tagAry,
  subtext,
  img,
}) => {
  return (
    <li
      className="relative z-10 cursor-pointer list-none overflow-hidden rounded-2xl 
      shadow-[5px_5px_30px_rgba(43,130,63,0.1)] duration-300 before:absolute before:top-0 before:left-0 before:-z-10 
      before:h-full before:w-full before:rounded-2xl before:duration-300 before:content-['']
      hover:before:border hover:before:border-solid hover:before:border-primary"
    >
      <MyLink to={link}>
        {/* 画像 */}
        {/*  local:pt-[72%] 本番：pt-0 h-[233px] pc:h-[240px]*/}
        <div className="relative h-[233px] w-full overflow-hidden rounded-t-2xl pc:h-[240px]">
          <WpImage
            image={img}
            className="absolute top-0 left-0 h-full w-full"
          />
        </div>
        <div className="px-6 pt-7 pb-8 pc:p-6">
          <h3 className="text-base font-medium text-primary pc:text-[20px]">
            {heading}
          </h3>
          {/* キャッチなど */}
          {description && (
            <p
              className="mt-2 text-base font-bold pc:text-xl"
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          )}
          {/* タグ */}
          <div className="mt-2 min-h-[60px] pc:mt-4">
            <ul className="flex flex-wrap gap-x-4 gap-y-2">
              {tagAry?.map((tag, index) => (
                <Label02
                  key={index}
                  label={(tag && Object.values(tag)[0]) || ""}
                />
              ))}
            </ul>
          </div>
          {subtext && (
            <p
              className="mt-4 text-base font-medium"
              dangerouslySetInnerHTML={{
                __html: subtext,
              }}
            ></p>
          )}
        </div>
      </MyLink>
    </li>
  );
};
