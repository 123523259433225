import { graphql, PageProps } from "gatsby";
import React, { useState } from "react";
import { Breadcrumb } from "../components/Breadcrumb";
import { Card01 } from "../components/Card01";
import { Heading01 } from "../components/Heading01";
import { Heading03 } from "../components/Heading03";
import { Layout } from "../components/Layout/index";
import { Tab01 } from "../components/Tab01";
import { Table01 } from "../components/Table01";
import bgimgPc from "../images/h1/h1product.png";
import bgimgSp from "../images/h1/h1product_sp.png";

const ProductIndexPage: React.VFC<PageProps<GatsbyTypes.ProductIndexQuery>> = ({
  data,
}) => {
  const [isShowTable, setIsShowTable] = useState(false);

  // 一覧から選ぶを押したときの動作
  const TabClicked01 = () => {
    if (isShowTable) {
      setIsShowTable(!isShowTable);
    }
  };

  // 比較表から選ぶを押したときの動作
  const TabClicked02 = () => {
    if (!isShowTable) {
      setIsShowTable(!isShowTable);
    }
  };
  return (
    <Layout title="製品一覧" description="ミスギの製品一覧ページです。">
      <Heading01
        label="PRODUCTS"
        smallLabel="製品一覧"
        bgimg={[bgimgPc, bgimgSp]}
      />
      <Breadcrumb labels={[{ url: "", text: "製品一覧" }]} />
      <div className="l-container py-8 pc:py-[120px]">
        <div id="slopecorner">
          <Heading03
            TagName={`h2`}
            label="スロープ・コーナー"
            bigLabel="SLOPE & CORNER"
          />
          {/* -----------------タブ------------- */}
          <div className="mx-auto mt-20 mb-16 flex w-full flex-col tb:flex-row pc:w-[84%]">
            <Tab01
              label="一覧から選ぶ"
              isSelected={!isShowTable}
              onClick={TabClicked01}
            />
            <Tab01
              label="比較表から選ぶ"
              isSelected={isShowTable}
              onClick={TabClicked02}
            />
          </div>
          {/* -----------------カードと表------------- */}
          <div className="mt-12">
            {isShowTable ? (
              <div className="l-container-sm">
                <h3 className="text-center text-xl font-bold pc:text-3xl">
                  比較表
                </h3>
                <p className="mx-auto mt-4 mb-8 w-full text-center text-sm pc:mt-8 pc:mb-16 pc:w-[800px] pc:text-base">
                  スロープ・コーナーの「材質」「最大荷重」「耐久性」「静音性」をわかりやすくまとめました。
                  <br />
                  お客様にピッタリの製品をお選びください。
                </p>
                <Table01 />
              </div>
            ) : (
              <ul className="grid grid-cols-1 gap-x-0 gap-y-8 tb:grid-cols-2 tb:gap-y-16 tb:gap-x-[30px] pc:grid-cols-3">
                {data.slopecorner.edges.map(
                  (nodes) =>
                    nodes.node.title && (
                      <Card01
                        key={nodes.node.title}
                        heading={nodes.node.title}
                        link={`/products/${nodes.node.slug || ``}`}
                        description={nodes.node.productAcf?.catch}
                        tagAry={nodes.node.tags?.nodes}
                        subtext={
                          nodes.node.productAcf?.price &&
                          `¥ ${nodes.node.productAcf?.price}<br/>
                        （税抜：¥ ${nodes.node.productAcf?.notaxprice}）`
                        }
                        img={nodes.node.productAcf?.listimg}
                      />
                    )
                )}
              </ul>
            )}
          </div>
        </div>
        <div id="carstopper" className="mt-[88px] pc:mt-[160px]">
          <Heading03
            TagName={`h2`}
            label="カーストッパー"
            bigLabel="CAR STOPPER"
          />
          <div className="mt-12">
            <ul className="grid grid-cols-1 gap-x-[30px] gap-y-16 tb:grid-cols-2 pc:grid-cols-3">
              {data.carstopper.edges.map(
                (nodes) =>
                  nodes.node.title && (
                    <Card01
                      key={nodes.node.title}
                      heading={nodes.node.title}
                      link={`/products/${nodes.node.slug || ``}`}
                      description={nodes.node.productAcf?.catch}
                      tagAry={nodes.node.tags?.nodes}
                      subtext={
                        nodes.node.productAcf?.price &&
                        `¥ ${nodes.node.productAcf?.price}<br/>
                        （税抜：¥ ${nodes.node.productAcf?.notaxprice}）`
                      }
                      img={nodes.node.productAcf?.listimg}
                    />
                  )
              )}
            </ul>
          </div>
        </div>
        <div id="cycleposition" className="mt-[88px] pc:mt-[160px]">
          <Heading03
            TagName={`h2`}
            label="サイクルポジション"
            bigLabel="CYCLE POSITION"
          />
          <div className="mt-12">
            <ul className="grid grid-cols-1 gap-x-[30px] gap-y-16 tb:grid-cols-2 pc:grid-cols-3">
              {data.cycleposition.edges.map(
                (nodes) =>
                  nodes.node.title && (
                    <Card01
                      key={nodes.node.title}
                      heading={nodes.node.title}
                      link={`/products/${nodes.node.slug || ``}`}
                      description={nodes.node.productAcf?.catch}
                      tagAry={nodes.node.tags?.nodes}
                      subtext={
                        nodes.node.productAcf?.price &&
                        `¥ ${nodes.node.productAcf?.price}<br/>
                        （税抜：¥ ${nodes.node.productAcf?.notaxprice}）`
                      }
                      img={nodes.node.productAcf?.listimg}
                    />
                  )
              )}
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ProductIndex {
    slopecorner: allWpProduct(
      sort: { fields: date, order: ASC }
      filter: {
        terms: { nodes: { elemMatch: { slug: { eq: "slopecorner" } } } }
      }
    ) {
      edges {
        node {
          title
          slug
          productAcf {
            listimg {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            catch
            price
            notaxprice
          }
          tags {
            nodes {
              name
            }
          }
        }
      }
    }
    carstopper: allWpProduct(
      sort: { fields: date, order: ASC }
      filter: {
        terms: { nodes: { elemMatch: { slug: { eq: "carstopper" } } } }
      }
    ) {
      edges {
        node {
          title
          slug
          productAcf {
            listimg {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            catch
            price
            notaxprice
          }
          tags {
            nodes {
              name
            }
          }
        }
      }
    }
    cycleposition: allWpProduct(
      sort: { fields: date, order: ASC }
      filter: {
        terms: { nodes: { elemMatch: { slug: { eq: "cycleposition" } } } }
      }
    ) {
      edges {
        node {
          title
          slug
          productAcf {
            listimg {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            catch
            price
            notaxprice
          }
          tags {
            nodes {
              name
            }
          }
        }
      }
    }
  }
`;

export default ProductIndexPage;
